import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInfrontStock, InfrontState, IUpdateTicker, UpdateStockField } from "./types";

const initialState = {
  stockData: [],
  status: "idle",
  error: null,
} as InfrontState;

const emptyStock = {
  ticker: "",
  last: 0,
  yesterdayClose: 0,
  change: 0,
  changePercent: 0,
  name: "",
  feed: 0,
  turnover: 0,
  isin: "",
  flag: "",
  instrumentSubType: "",
} as IInfrontStock;

const updateStock = (state: InfrontState, action: PayloadAction<IUpdateTicker>, field: UpdateStockField) => {
  const stock: IInfrontStock | undefined = state.stockData.find(o => o.ticker === action.payload.ticker);
  if (stock) {
    stock[field] = action.payload.value as string | number;
  } else {
    state.stockData.push({
      ...emptyStock,
      ticker: action.payload.ticker,
      [field]: action.payload.value,
    });
  }
};

export const infront = createSlice({
  name: "infront",
  initialState,
  reducers: {
    setLast: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "last");
    },
    setYesterdayClose: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "yesterdayClose");
    },
    setChange: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "change");
    },
    setChangePercent: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "changePercent");
    },
    setName: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "name");
    },
    setFeed: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "feed");
    },
    setTurnover: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "turnover");
    },
    setIsin: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "isin");
    },
    setFlag: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "flag");
    },
    setInstrumentSubType: (state, action: PayloadAction<IUpdateTicker>) => {
      updateStock(state, action, "instrumentSubType");
    },
  },
});
export const {
  setLast,
  setYesterdayClose,
  setChange,
  setChangePercent,
  setName,
  setFeed,
  setTurnover,
  setIsin,
  setFlag,
  setInstrumentSubType,
} = infront.actions;
export default infront.reducer;
