import { PortalData } from "@portal/assets/values/Values";
import { IModifySavingsAgreement } from "@portal/types/api/IModifySavingsAgreement";

export function IsValidRequest(agreement: IModifySavingsAgreement) {
  return HasSelectedFund(agreement) && AllFundsHaveAnAmount(agreement) && IsValidFundAmount(agreement);
}

export function HasSelectedFund(agreement: IModifySavingsAgreement) {
  return agreement.funds.length > 0;
}

export function AllFundsHaveAnAmount(agreement: IModifySavingsAgreement) {
  return agreement.funds.every(f => f.amount > 0);
}

export function GetTotalFundAmount(agreement: IModifySavingsAgreement) {
  let total = 0;

  agreement.funds.forEach(f => {
    total += f.amount;
  });

  return total;
}

export function IsValidFundAmount(agreement: IModifySavingsAgreement) {
  return GetTotalFundAmount(agreement) >= PortalData.MINIMUM_MONTHLY_AMOUNT;
}

export function ValidatePeriodDay(periodDayNumber?: number) {
  if (periodDayNumber === undefined) return false;
  return periodDayNumber > 0 && periodDayNumber <= 31;
}

export function ValidateName(name?: string): boolean {
  return !!name;
}
