import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isDefaultLanguage: true,
};

export const languageReducer = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<boolean>) => {
      state.isDefaultLanguage = action.payload;
    },
  },
});

export const { setLanguage } = languageReducer.actions;
export default languageReducer.reducer;
