import { ISwitchOrder } from "@portal/types/api/ISwitchOrder";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  switchOrderCount: 0,
};

export const getSwitchOrderCount = createAsyncThunk("getSwitchOrderCount", async () => {
  const requestOptions = {
    method: "GET",
    headers: { accept: "application/json" },
  };
  const url = "/api/funds/switch";
  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    console.error(response.statusText);
    return;
  }
  const orders = (await response.json()) as ISwitchOrder[];
  return orders.filter(o => o.fundOrderStatus !== "K" && o.fundOrderStatus !== "Ss")?.length ?? 0;
});

export const switchOrderCount = createSlice({
  name: "switchOrderCount",
  initialState,
  reducers: {
    clearCount: state => {
      state.switchOrderCount = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSwitchOrderCount.fulfilled, (state, action) => {
      state.switchOrderCount = action.payload ?? 0;
    });
  },
});

export default switchOrderCount.reducer;
