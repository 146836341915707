import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RiskLevel } from "@portal/types/robot/IRobotEnums";
import { ISavingAgreementData } from "@portal/types/robot/ISavingAgreementData";
import { ISavingCalculations } from "@portal/types/robot/ISavingCalculations";
import { PortalData } from "@portal/assets/values/Values";
import { ISavingAgreementAdditionalFunds } from "@portal/types/robot/ISavingAgreementAdditionalFunds";

export interface SavingCalculationsResponseObject {
  data: ISavingCalculations;
  isLoading: boolean;
  error: boolean;
}

interface ISavingCalculationsObject {
  request: ISavingAgreementData;
  response: SavingCalculationsResponseObject;
}

const additionalFunds = [] as ISavingAgreementAdditionalFunds[];

const initialRequest = {
  riskLevel: null,
  monthlyAmount: PortalData.DEFAULT_MONTHLY_AMOUNT,
  numOfcalculationsEachYear: PortalData.DEFAULT_NUM_CALC_EACH_YEAR,
  numOfMonthsStartupAmount: PortalData.DEFAULT_MONTHS_STARTUP_AMOUNT,
  numOfYears: PortalData.DEFAULT_YEARS,
  oneTimeDeposit: PortalData.DEFAULT_ONE_TIME_DEPOSIT,
  sustainability: null,
  savingAgreementAdditionalFunds: additionalFunds,
  comment1: "",
  comment2: "",
} as ISavingAgreementData;

const initialResponse = {
  data: {},
  isLoading: true,
  error: false,
} as SavingCalculationsResponseObject;

const initialState = {
  request: initialRequest,
  response: initialResponse,
} as ISavingCalculationsObject;

export const savingCalculationsReducer = createSlice({
  name: "savingCalculations",
  initialState,
  reducers: {
    clearSavingCalculationsData: state => {
      state.request = initialRequest;
      state.response = initialResponse;
    },
    // REQUEST
    setCalculationsRiskLevel: (state, action: PayloadAction<RiskLevel>) => {
      state.request.riskLevel = action.payload;
    },
    setMonthlyAmount: (state, action: PayloadAction<number>) => {
      state.request.monthlyAmount = action.payload;
    },
    setNumOfYears: (state, action: PayloadAction<number>) => {
      state.request.numOfYears = action.payload;
    },
    setNumOfCalculationsEachYear: (state, action: PayloadAction<number>) => {
      state.request.numOfcalculationsEachYear = action.payload;
    },
    setNumOfMonthsStartupAmount: (state, action: PayloadAction<number>) => {
      state.request.numOfMonthsStartupAmount = action.payload;
    },
    setCalculationsSustainability: (state, action: PayloadAction<boolean>) => {
      state.request.sustainability = action.payload;
    },
    setOneTimeDeposit: (state, action: PayloadAction<number>) => {
      state.request.oneTimeDeposit = action.payload;
    },
    // RESPONSE
    setSavingCalculationsResponse: (state, action: PayloadAction<SavingCalculationsResponseObject>) => {
      state.response = action.payload;
    },
  },
});

export const {
  setCalculationsRiskLevel,
  setMonthlyAmount,
  setNumOfYears,
  setNumOfCalculationsEachYear,
  setNumOfMonthsStartupAmount,
  setCalculationsSustainability,
  setOneTimeDeposit,
  setSavingCalculationsResponse,
  clearSavingCalculationsData,
} = savingCalculationsReducer.actions;
export default savingCalculationsReducer.reducer;
