import { ISwitchFundToBuy } from "@portal/types/api/ISwitchMultiFundOrderRequest";

export const validateAmount = (amount: string): string => (amount !== "" && amount !== "0" ? "" : "Beløp mangler.");

export const validateQuantity = (quantity: number, positionQuantity: number): string => {
  if (!isNaN(Number(positionQuantity)) && quantity > positionQuantity) {
    return "Beløpet overstiger dine verdier i fondet";
  }
  return "";
};

export const validateToFund = (toFunds: ISwitchFundToBuy[]) => {
  if (toFunds.length === 0) {
    return "Du må velge et fond å bytte til";
  }
  return "";
};
