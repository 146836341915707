import { registerStockObserverForPositions } from "@portal/infront/stockData";
import { IPosition } from "@portal/types/api/IPosition";
import { IPositionUpdate } from "@portal/types/api/IPositionUpdate";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PositionsState } from "./types";
import { mapSBMFundName } from "@portal/api/utils/mappers/fund";

const initialState = {
  positions: [],
  status: "idle",
  error: null,
} as PositionsState;

export interface IFetchPositionsRequest {
  registerStockObserver: boolean;
  centerId?: string;
}

export const fetchPositions = createAsyncThunk("positions/fetchPositions", async (req: IFetchPositionsRequest, thunkAPI) => {
  return fetch("/api/positions", {
    method: "GET",
    headers: [["accepts", "application/json"]],
  }).then(async res => {
    if (res.status === 200) {
      const positions = (await res.json()) as IPosition[];
      const positionsMapped = positions.map(p => {
        return {
          ...p,
          instrumentName: mapSBMFundName(p.instrumentName ?? "", req.centerId),
        };
      });
      if (req.registerStockObserver) {
        registerStockObserverForPositions(positionsMapped, thunkAPI.dispatch);
      }
      return positionsMapped;
    }
    throw new Error(res.statusText);
  });
});

export const positions = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setPositions: (state, action: PayloadAction<IPosition[]>) => {
      state.positions = action.payload;
    },
    setStockPrice: (state, action: PayloadAction<{ tickerCode: string; price: number }>) => {
      const position = state.positions.find(o => o.tickerCode === action.payload.tickerCode);
      if (position) {
        position.marketPrice = action.payload.price;
      }
    },
    positionUpdate: (state, action: PayloadAction<IPositionUpdate>) => {
      const position = state.positions.find(
        o => o.portfolioId === action.payload.portfolioId && o.instrumentId === action.payload.instrumentId,
      );

      if (position) {
        position.quantity = action.payload.position;
        position.instrumentRate = action.payload.instrumentRate;
        position.posValue = action.payload.posValue;
        state.positions = [
          ...state.positions.filter(
            o => !(o.portfolioId === action.payload.portfolioId && o.instrumentId === action.payload.instrumentId),
          ),
          position,
        ];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPositions.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchPositions.fulfilled, (state, action: PayloadAction<IPosition[]>) => {
        const positions = action.payload;
        state.status = "succeeded";
        state.positions = positions;
      })
      .addCase(fetchPositions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      });
  },
});
export const { positionUpdate, setPositions } = positions.actions;
export default positions.reducer;
