import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRiskLevel } from "@portal/types/robot/IRiskLevel";
import { IRiskLevelProposalResponse } from "../../hooks/robot/useRiskLevelProposal/types";
import {
  AvailableLiquidity,
  Behaviour,
  Experience,
  RiskAppetite,
  SavingOfTotalEconomy,
  SavingPeriod,
  ValuesEnum,
} from "../../types/robot/IRobotEnums";

interface IRiskLevelData {
  request: IRiskLevel;
  response: IRiskLevelProposalResponse;
  returningUser: boolean;
}

const initialRequest = {
  savingPeriod: null,
  riskAppetite: null,
  behaviour: null,
  savingsToday: null,
  sharesToday: null,
  experience: null,
  relevantEduationOrJob: null,
  availableLiquidity: null,
  moreValuesThanDebt: null,
  savingOfTotalEconomy: null,
  sustainability: null,
  sustainabilitySfdr: null,
  sustainabilityEu: null,
  sustainabilityPai: null,
  needMoreInfo: null,
} as IRiskLevel;

const initialResponse = {
  isLoading: false,
  data: [{ name: null, shares: 0, interest: 0, default: true, riskLevelScore: 0 }],
  error: null,
} as IRiskLevelProposalResponse;

const initialState = {
  request: initialRequest,
  response: initialResponse,
  returningUser: false,
} as IRiskLevelData;

export const riskLevelReducer = createSlice({
  name: "riskLevel",
  initialState,
  reducers: {
    clearRiskLevelData: state => {
      state.request = initialRequest;
      state.response = initialResponse;
      state.returningUser = false;
    },
    // REQUEST
    setExperience: (state, action: PayloadAction<Experience>) => {
      state.request.experience = action.payload;
      state.returningUser = true;
    },
    setRelevantEducationOrJob: (state, action: PayloadAction<boolean>) => {
      state.request.relevantEduationOrJob = action.payload;
      state.returningUser = true;
    },
    setSavingPeriod: (state, action: PayloadAction<SavingPeriod>) => {
      state.request.savingPeriod = action.payload;
      state.returningUser = true;
    },
    setRiskAppetite: (state, action: PayloadAction<RiskAppetite | null>) => {
      state.request.riskAppetite = action.payload;
      state.returningUser = true;
    },
    setBehaviour: (state, action: PayloadAction<Behaviour>) => {
      state.request.behaviour = action.payload;
      state.returningUser = true;
    },
    setAvailableLiquidity: (state, action: PayloadAction<AvailableLiquidity>) => {
      state.request.availableLiquidity = action.payload;
      state.returningUser = true;
    },
    setSavingsToday: (state, action: PayloadAction<ValuesEnum>) => {
      state.request.savingsToday = action.payload;
      state.returningUser = true;
    },
    setSharesToday: (state, action: PayloadAction<ValuesEnum>) => {
      state.request.sharesToday = action.payload;
      state.returningUser = true;
    },
    setMoreValuesThanDebt: (state, action: PayloadAction<boolean>) => {
      state.request.moreValuesThanDebt = action.payload;
      state.returningUser = true;
    },
    setSavingOfTotalEconomy: (state, action: PayloadAction<SavingOfTotalEconomy>) => {
      state.request.savingOfTotalEconomy = action.payload;
      state.returningUser = true;
    },
    setNeedMoreInfo: (state, action: PayloadAction<boolean>) => {
      state.request.needMoreInfo = action.payload;
      state.returningUser = true;
    },
    setSustainability: (state, action: PayloadAction<boolean>) => {
      state.request.sustainability = action.payload;
      state.returningUser = true;
    },
    setSustainabilitySfdr: (state, action: PayloadAction<boolean>) => {
      state.request.sustainabilitySfdr = action.payload;
      state.returningUser = true;
    },
    setSustainabilityPai: (state, action: PayloadAction<boolean>) => {
      state.request.sustainabilityPai = action.payload;
      state.returningUser = true;
    },
    setSustainabilityEu: (state, action: PayloadAction<boolean>) => {
      state.request.sustainabilityEu = action.payload;
      state.returningUser = true;
    },
    // RESPONSE
    setRiskLevelResponse: (state, action: PayloadAction<IRiskLevelProposalResponse>) => {
      state.response = action.payload;
    },
  },
});

export const {
  setSavingPeriod,
  setRiskAppetite,
  setBehaviour,
  setSavingsToday,
  setSharesToday,
  setExperience,
  setRelevantEducationOrJob,
  setAvailableLiquidity,
  setMoreValuesThanDebt,
  setRiskLevelResponse,
  setSavingOfTotalEconomy,
  setNeedMoreInfo,
  setSustainability,
  setSustainabilitySfdr,
  setSustainabilityPai,
  setSustainabilityEu,
  clearRiskLevelData,
} = riskLevelReducer.actions;
export default riskLevelReducer.reducer;
