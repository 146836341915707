import { InfrontSDK } from "@infront/infront-sdk";
import { InfrontSDKSingleton } from "./infrontSDK";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setChange,
  setChangePercent,
  setName,
  setFeed,
  setYesterdayClose,
  setLast,
  setTurnover,
  setIsin,
  setFlag,
  setInstrumentSubType,
} from "../redux/reducers/api/infront/reducer";
import { updateLastPrice, updateYesterdayClose } from "@portal/redux/reducers/api/orders/reducer";

export const subscriber = (feedId: number, tickerCode: string, dispatch: Dispatch) => {
  InfrontSDKSingleton?.getInstance()?.get(
    InfrontSDK.symbolData({
      id: {
        feed: feedId,
        ticker: tickerCode,
      },
      subscribe: true,
      onData: (data: InfrontSDK.SymbolData) => {
        data.observe(InfrontSDK.SymbolField.YesterdayClose, (value: number | string) => {
          dispatch(setYesterdayClose({ ticker: tickerCode, value }));
          dispatch(updateYesterdayClose({ ticker: tickerCode, yesterdayClose: value as number }));
        });
        data.observe(InfrontSDK.SymbolField.Last, (value: number | string) => {
          dispatch(setLast({ ticker: tickerCode, value }));
          dispatch(updateLastPrice({ ticker: tickerCode, lastPrice: value as number }));
        });
        data.observe(InfrontSDK.SymbolField.Change, (value: number | string) => {
          dispatch(setChange({ ticker: tickerCode, value }));
        });
        data.observe(InfrontSDK.SymbolField.ChangePercent, (value: number | string) => {
          dispatch(setChangePercent({ ticker: tickerCode, value }));
        });
        data.observe(InfrontSDK.SymbolField.FullName, (value: number | string) => {
          dispatch(setName({ ticker: tickerCode, value }));
          // must set feed here since listening to feed symbol will return feed on all requested feeds
          dispatch(setFeed({ ticker: tickerCode, value: feedId }));
        });
        data.observe(InfrontSDK.SymbolField.Turnover, (value: number | string) => {
          dispatch(setTurnover({ ticker: tickerCode, value }));
        });
        data.observe(InfrontSDK.SymbolField.ISIN, (value: number | string) => {
          dispatch(setIsin({ ticker: tickerCode, value }));
        });
        data.observe(InfrontSDK.SymbolField.CountryFlag, (value: number | string) => {
          dispatch(setFlag({ ticker: tickerCode, value }));
        });
        data.observe(InfrontSDK.SymbolField.SymbolSubType, (value: number | string) => {
          dispatch(setInstrumentSubType({ ticker: tickerCode, value }));
        });
      },
      onError: error => console.error("Error:", error),
    }),
  );
};
