import { IFund } from "@portal/types/api/IFund";

export const mapSBMFundInfo = (apiFund: IFund, centerId?: string): IFund => {
  if ((centerId === "3911" || centerId === "3910") && apiFund.name.toLowerCase().startsWith("norne")) {
    return { ...apiFund, name: `SBM ${apiFund.name}` };
  } else {
    return apiFund;
  }
};

export const mapSBMFundName = (fundName: string, centerId?: string): string => {
  if ((centerId === "3911" || centerId === "3910") && fundName.toLowerCase().startsWith("norne")) {
    return `SBM ${fundName}`;
  } else {
    return fundName;
  }
};
