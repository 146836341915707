import { IFund } from "@portal/types/api/IFund";
import { IPortfolio } from "@portal/types/api/IPortfolio";
import { IPosition } from "@portal/types/api/IPosition";
import { ISwitchFundToBuy } from "@portal/types/api/ISwitchMultiFundOrderRequest";

export enum Steps {
  fromFund,
  toFund,
  confirmation,
  receipt,
}

export interface ISwitchFundFormData {
  fromFundIsin?: string;
  position: IPosition | undefined;
  amount: string;
  quantity: number;
  quantityAll: boolean;
  toFunds: ISwitchFundToBuy[];
  portfolio: IPortfolio | undefined;
}

export interface ISwitchFund {
  formData: ISwitchFundFormData;
  amountError: string;
  toFundError: string;
  apiError: string;
  loading: boolean;
  receiptData: IReceipt | null;
  step: Steps;
}

export interface ISwitchFundRequest {
  fund: IFund;
  position: IPosition;
}

export interface IReceipt {
  fromFundIsin: string;
  toFunds: ISwitchFundToBuy[];
  quantity: number;
}
