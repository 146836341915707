import { addDays, addMonths, format } from "date-fns";

export function GetEndDateForStartUpAgreement(numOfMonths: number) {
  const currentDate = addDays(addMonths(new Date(), numOfMonths), -5);

  return format(currentDate, "yyyy-MM-dd");
}

export function GetInitialPeriodDayNumber() {
  const currentDate = addDays(new Date(), 5);
  const periodDay = format(currentDate, "yyyy-MM-dd").substring(8, 10);

  return Number(periodDay);
}
